<template>
  <div class="wrapper new-announcement" ref="page">
    <ctheader />
    <div class="content">
      <div class="form">
        <div class="field">
          <label>标题</label>
          <span>{{ detail.title }}</span>
        </div>
        <div class="field">
          <label>发送</label>
          <span :title="customerAll">{{ customer }}</span>
        </div>
        <div class="field">
          <label>类型</label>
          <span>{{ detail.type_name }}</span>
        </div>
        <div class="field">
          <label>详情</label>
          <div class="editor" v-html="detail.content"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
// import ctheader from '@@/ctheader';
import VueUeditorWrap from "vue-ueditor-wrap";
import { apiPost } from "../../modules/utils";
import APIs from "../../modules/APIs";
// import router from '@/router';
// import * as URL from '_/URLs';
// import radio from '@@/radio';
// import layers from '@@/layers';
import router from "../../router";
import ctheader from "../../components/ctheader";
import radio from "../../components/radio";
import layers from "../../components/layers";
import { event } from "../../modules/EVENTs";

const getters = mapGetters([]);

const actions = mapActions(["setAlert"]);

/**
 * @module index.vue
 * @desc this is module index.vue
 * @author songkexin <songkexin@rongyi.com>
 * @since 2021-04-09
 * @copyright 2021
 */
export default {
  name: "announcement",
  components: {
    ctheader,
    VueUeditorWrap,
    radio,
    layers,
  },
  computed: {
    checkedList() {
      return this.customerList.filter((el) => el.checked).map((el) => el.code);
    },
    isCheckAll() {
      return (
        this.customerList.length === this.checkedList.length &&
        this.checkedList.length > 0
      );
    },
    ...getters,
  },
  created() {
    console.log(this.$route);
    event.$on("sdnm-radio", this.sdnmRadio);
    this.getDetail();
  },
  beforeDestroy() {
    event.$off("sdnm-radio", this.sdnmRadio);
  },
  data() {
    return {
      detail: {},
      customer: "",
      customerAll: "",
    };
  },
  methods: {
    getDetail() {
      let $this = this;
      apiPost({
        url: APIs.ANNOUNCEMENT,
        data: {
          action: "detail",
          id: this.$route.params.id,
        },
        success(res) {
          if (res.code === "0") {
            $this.detail = res.data;
            if (res.data.company_info.length === 0) {
              $this.customer = "全部客户";
            } else if (res.data.company_info.length > 10) {
              $this.customer = res.data
                .slice(0, 10)
                .map((el) => el.name)
                .join("、");
            } else {
              $this.customer = res.data.company_info
                .map((el) => el.name)
                .join("、");
            }
            $this.customerAll = res.data.company_info
              .map((el) => el.name)
              .join("、");
          }
        },
        error(res) {
          $this.setAlert({ msg: "获取数据失败" });
        },
      });
    },
    ...actions,
  },
};
</script>
<style lang="less">
@import url("../../less/common.less");
.new-announcement {
  .mgr-5 {
    margin-right: 5px;
  }
  .mgl-20 {
    margin-left: 20px;
  }

  &.wrapper .content {
    overflow: auto;
    padding-bottom: 70px;
  }
  .form .field {
    display: flex;
    label {
      .dib();
      width: 80px;
    }
    .editor {
      flex: 1;
    }
    .desc {
      .fc(12px, #666);
    }
    a {
      cursor: pointer;
    }
  }
  #layer-content .layer {
    z-index: 1000;
    .layer-container {
      .pd(20px);
    }
  }
}
</style>
